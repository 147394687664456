<!-- AccountSelection.vue -->
<template>
    <div>
        <div class="form-row">
            <div class="form-label" id="lblAccounts">Account(s):</div>

            <div class="checkbox-container">
                <input id="all-sites" v-model="all_sites" class="chk" type="checkbox" />
                <label for="all-sites">All sites</label>
            </div>
        </div>

        <template v-if="!all_sites">
            <div class="form-row">
                <div class="form-label">&nbsp;</div>

                <div class="form-input-holder">
                    <input aria-labelledby="lblAccounts" type="text" class="form-input" v-model.trim="site_search"
                        placeholder="Search by site number or name" @input="page_number = 1" />
                </div>
            </div>

            <table class="reports-accounts">
                <thead>
                    <tr>
                        <td>Site Number</td>
                        <td>Site Name</td>
                        <td>Customer Ref</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="account in pagedAccounts" :key="account.account_number">
                        <td>{{ account.formattedAccountNumber }}</td>
                        <td>{{ account.company }}</td>
                        <td>{{ account.customer_ref }}</td>
                        <td>
                            <input type="checkbox" v-model="account_numbers" :value="account.account_number" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="reports-accounts-nav">
                <a href="" v-if="page_number > 1" class="prev" @click.prevent="page_number--">&lt; Prev</a>
                <a href="" v-if="page_number < numberOfPages" class="next" @click.prevent="page_number++">Next &gt;</a>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: ["accounts"],
    name: 'AccountSelection',
    data() {
        return {
            all_sites: false,
            account_numbers: [],
            site_search: '',
            page_number: 1,
            page_size: 5,
        };
    },
    computed: {
        numberOfPages: function () {
            return Math.ceil(this.filteredAccounts.length / this.page_size)
        },
        pagedAccounts() {
            return this.filteredAccounts.slice((this.page_number - 1) * this.page_size, this.page_number * this.page_size)
        },
        filteredAccounts() {
            if (!this.site_search) return this.accounts

            var site_search = this.site_search.toLowerCase()

            return this.accounts.filter(
                a =>
                    (a.account_number && a.account_number.includes(site_search)) ||
                    (a.formattedAccountNumber && a.formattedAccountNumber.toLowerCase().includes(site_search)) ||
                    (a.company && a.company.toLowerCase().includes(site_search)) ||
                    (a.customer_ref && a.customer_ref.toLowerCase().includes(site_search))
            )
        },
    },
    watch: {
        all_sites(newValue) {
            this.$emit('update:all_sites', newValue);
        },
        account_numbers(newValue) {
            this.$emit('update:account_numbers', newValue);
        },
    },
};
</script>

<style scoped>
.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 5rem;
    margin-bottom: 2rem;
}

.form-input {
    width: 100%;
}

.form-label {
    padding: 1rem 0;
}

.form-input::placeholder {
    color: #ccc;
}

.reports-accounts {
    width: 100%;
    border-collapse: collapse;
    margin-top: 2rem;
}

.reports-accounts td {
    padding: 1rem 2rem;
    border-top: 1px solid #eee;
}

.reports-accounts thead td {
    background: #eee;
    font-weight: bold;
}

.reports-accounts tr:nth-child(even) td {
    background: #f8f8f8;
}

.reports-accounts input {
    cursor: pointer;
    transform: scale(1.3);
}

.reports-accounts-nav {
    margin-bottom: 3rem;
    text-align: right;
}

.reports-accounts-nav a {
    background: #dc002e;
    display: inline-block;
    border-radius: 0.4rem;
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-left: 1rem;
    color: #fff;
}

.reports-accounts-nav a:hover {
    background: #c4002a;
}

@media screen and (max-width: 600px) {
    .form-row {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
</style>